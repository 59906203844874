@media (max-width: 699.99px) {
    .footer-line-1 {
        color: white;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .Footer {
        height: 550px;
        background-color: rgb(213, 121, 45);
    }
}
  
@media (min-width: 700px) {
    .footer-line-1 {
        color: white;
        display: flex;
        margin: 0 auto;
    }

    .Footer {
        height: 380px;
        background-color: rgb(237, 170, 153);
    }
}

.pre-footer {
    height: 110px;
    width: 100%;
    background-color: rgb(236, 193, 202);
}

.social-icon {
    margin: 35px 0px 35px 0px;
}

.main-footer {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
}

.privacy-link {
    text-decoration: none;
    color: white;
    margin-bottom: 20px;
}

.line-1-p {
    margin: 30px;
}

.footer-line-2 {
    color: gray;
}