.nav {
  position: absolute;
  top: 0px;
  height: 140px;
  width: 100%;
  display: flex;
  color: white;
}

/* mobile view */
@media (max-width: 999.99px) {
  .logo {
    margin: auto;
    margin-top: 30px;
    margin-left: 10px;
    height: 40px;
    display: flex;
  }

  .company-name {
    font-family: 'Courier New', Courier, monospace;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    width: 100px;
    height: 40px;
    position: relative;
    left: 5px;
    bottom: 42px;
  }

  .nav-menu {
    display: none;
  }

  .menu-button {
    position: absolute;
    top: -20px;
    right: 0px;
    height: 75px;
    width: 75px;
    margin-top: 25px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .nav-menu-link {
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
    text-decoration: none;
    color: black;
  }
}

/* desktop view */
@media (min-width: 1000px) {
  .logo {
    margin: 25px auto;
    margin-top: 60px;
    margin-left: 25px;
    height: 80px;
    display: flex;
  }
  
  .company-name {
    font-family: 'Courier New', Courier, monospace;
    font-size: 26px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    margin: auto;
    width: 25px;
    position: relative;
    bottom: 92px;
    left: 12px;
  }

  .nav-menu {
    margin: auto;
    margin-right: 20px;
  }

  .menu-button {
    display: none;
  }

  .nav-menu-link {
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    font-weight: bold;
    margin-right: 50px;
    text-decoration: none;
    color: black;
  }
}

.hamburger {
  height: 50px;
  width: 50px;
}

.mobile-menu {
  margin-top: 80px;
  background-color: gray;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  z-index: 10;
  position: absolute;
  right: 0;
}

.mobile {
  margin-right: 0px;
}
