/* mobile view */
@media (max-width: 1299.99px) {
    .home-body {
        margin-top: 800px;
    }

    .banner-button-text {
        color: rgb(5, 0, 0);
        position: relative;
        bottom: 150px;
        right: 0px;
        font-size: 25px;
    }

    .header-content {
    }

    .header-img {
        position: absolute;
        top: -125px;
        left: 0;
        width: 100%;
        height: 850px;
        z-index: -1;
        object-fit: cover;
        object-position: -400px;
    }
}

/* desktop view */
@media (min-width: 1300px) {
    .home-body {
        margin-top: 800px;
        padding: 0 100px;
        font-size: medium;
    }
    
    .banner-button-text {
        color: white;
        position: relative;
        bottom: 310px;
        right: 100px;
        font-size: 25px;
    }

    /* .header-content {
        position: absolute;
        left: 200px;
        top: 450px;
    } */

    .header-img {
        position: absolute;
        top: -125px;
        left: 0;
        width: 100%;
        height: 850px;
        z-index: -1;
        object-fit: cover;
    }
}

.button-1 {
    background-color: rgb(236, 193, 202);
    height: 50px;
    width: 175px;
    border-radius: 25px;
    border: none;
    color: black;
}
.main-text {
    color: white;
    font-size: xxx-large;
}
.header-text {
    color: white;
    font-family:'Proxima-nova'
}

.home-body-text {
    margin-bottom: 50px;
}

.banner-button-link {
    text-decoration: none;
}

.banner-button-img {
    width: 100%;
    height: 350px;
    border-radius: 45px;
    object-fit: cover;
}
