/* mobile view */
@media (max-width: 999.99px) {
    .form-input {
        height: 20px;
        width: 300px;
        border-radius: 50px;
        border-style: solid;
        border-width: 1px;
        margin: 10px 0px 15px;
        padding: 10px 16px;
    }

    select {
        height: 45px;
        width: 515px;
        border-radius: 50px;
        margin-bottom: 15px;
        padding: 10px 16px;
        margin-top: 10px;
        width: 334px;
        color: black;
    }

    .misc {
        height: 98px;
        width: 300px;
        padding: 10px 16px;
        border-radius: 20px;
        margin: 15px 0px;
    }
}

/* desktop view */
@media (min-width: 1000px) {
    .form-input {
        height: 20px;
        width: 480px;
        border-radius: 50px;
        border-style: solid;
        border-width: 1px;
        margin: 10px 0px 15px;
        padding: 10px 16px;
    }

    select {
        height: 45px;
        width: 515px;
        border-radius: 50px;
        margin-bottom: 15px;
        padding: 10px 16px;
        margin-top: 10px;
        color: black;
    }

    .misc {
        height: 98px;
        width: 480px;
        padding: 10px 16px;
        border-radius: 20px;
        margin-bottom: 15px;
    }
}

.pricing-header-img {
    position: absolute;
    top: -500px;
    left: 0;
    width: 100%;
    height: 885px;
    z-index: -1;
    object-fit: cover;
}

.pricing-body {
    margin-top: 420px;
}

.packageDescriptions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packageDescription {
    text-align: left;
    border-left: #e3e3e3 solid 1px;
    padding-left: 2vw;
}

.descriptionButton {
    width: 100%;
    height: 50px;
    outline: solid 3px;
    outline-offset: 3px;
    outline-color: black;
    color: black;
    margin: 30px;
    padding: 10px;
    background-color: hsl(97, 96%, 71%);
    font-size: 1.3rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.quote-form {
    margin-top: 50px;
    position: relative;
    align-items: center;
}

.quote-label {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.column {
    display: flex;
    flex-direction: column;
}

.provider {
    margin-top: 15px;
    font-weight: normal;
}

.quality {
    font-weight: normal;
    margin-top: 15px;
}

.quote-button {
    position: relative;
    margin-top: 25px;
}

.margin-bottom {
    margin-bottom: 25px; 
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin: 0 auto;
}
